import type { C9Map } from '$lib/models/object.models';

export const ADS_ID: string = '/22377147561/';
export const ADS_CONTAINER_ID: string = 'div-gpt-ad-slot-';
export const INTER_BANNER_SIZES: googletag.GeneralSize = [[350, 100], [468, 100], [640, 100], [728, 100]];
export const TOP_BANNER_SIZES: googletag.GeneralSize = [[350, 60], [468, 60], [640, 60], [728, 60], [900, 60], [1024, 60]];
export const INTER_BANNER_SIZES_MAPPING: C9Map<googletag.GeneralSize> = {
    0: [350, 100],
    480: [468, 100],
    640: [640, 100],
    768: [728, 100],
    1024: [[728, 100], [640, 100]],
};
export const TOP_BANNER_SIZES_MAPPING: C9Map<googletag.GeneralSize> = {
    0: [350, 60],
    480: [468, 60],
    640: [640, 60],
    768: [728, 60],
    1024: [[1024, 60], [900, 60]],
};

export const TOP_BANNER_ADS: C9Map<string> = {
    '/consultanta-administrare/avocat': 'B1_Avocat',
    '/consultanta-administrare/contabilitate': 'B1_Conta',
    '/sanatate-ingrijire/medici': 'B1_medic',
    default: 'casa9_b1hp_728x90',
};

export const INTER_BANNER_ADS: C9Map<string | string[]> = {
    '/consultanta-administrare/avocat': 'B2_AvocatInter',
    '/consultanta-administrare/contabilitate': ['B2ContabilIntercalat', 'B2ContaInter2'],
    '/sanatate-ingrijire/medici': 'B2_MedicInter',
};
