import Bowser from 'bowser';

export const preventDefAndStopProp = (event: Event) => {
    event?.preventDefault?.();
    event?.stopPropagation?.();
};

/**
 * Returns true if the device is thought to be a mobile or tablet
 * @returns {boolean}
 */
export const isMobileOrTablet = (): boolean => {
    if (typeof navigator !== 'undefined') {
        const platformType = Bowser.getParser(navigator.userAgent).getPlatformType(true);
        return platformType === Bowser.PLATFORMS_MAP.mobile || platformType === Bowser.PLATFORMS_MAP.tablet;
    }
    return false;
};

export const isMobile = (): boolean => typeof navigator !== 'undefined' && Bowser.getParser(navigator.userAgent).getPlatformType(true) === Bowser.PLATFORMS_MAP.mobile;

export const isIOS = (): boolean => {
    if (typeof navigator !== 'undefined') {
        const osName = Bowser.getParser(navigator?.userAgent).getOSName();
        return osName === Bowser.OS_MAP.iOS || osName === Bowser.OS_MAP.MacOS;
    }
    return false;
};
